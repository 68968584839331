<template>
 
 <div class="play">
    <a href="#" id="showPopup" class="video-play-button" >
      <span></span>
    </a>
  <p>Watch how <strong>kpododo consult</strong> can help you in your journey to change the world</p>
</div>
    <div class="bg" style="display:none"></div>

    <div class="popup" style="display:none;height: 100%; width:100%;">
      <iframe id="player" class="test3" width="1280" height="720" src="https://www.youtube.com/embed/_GZ0sH_eEfA" title="Introducing Kpododo Consult" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
mounted() {
    $(function() {
  $("#showPopup").click(function(e) {
    e.stopPropagation();
    $(".bg").toggle();
    $(".popup").toggle();
  });
  $("body").click(function() {
    $(".bg").toggle();
    $(".popup").hide();
    var video = $("#player").attr("src");
    $("#player").attr("src", "");
    $("#player").attr("src", video);
  });
});
}
}
   
</script>
<style scoped>
.popup {
  position: absolute;
  min-height: auto;
  width: auto;
  z-index: 1002;
}

.test {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  height: 80%;
  margin: auto;
}

.test2 {
  position: absolute;
  margin-left: 10%;
  margin-top: 10%;
  width: 80%;
  height: 80%
}

.test3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80%;
  height: 80%
}

.bg {
  background-color: black;
  opacity: 0.65;
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.play{
 position: absolute;
 left: 60%;
 top: 80%;
}

.play p{
  width: 400px;
  color:#fff;
text-align: left;
  font-size: 20px;
  font-family: 'Inter Variable', sans-serif;
  line-height: 1.7;
  letter-spacing: -0.0075rem;
}
.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: -10%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #eb2055; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 55px;
  height: 55px;
  background: #f48116;
  border-radius: 50%;
  animation: pulse-border 1100ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #f48116;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 19px solid #fff;
	border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  top: 10px;
  left: 5px;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}


@media screen and (max-width: 970px) {
    .play {
    position: absolute;
    left: 20%;
    top: 90%;
}
.play p {
    width: 290px;
    color: #fff;
    text-align: left;
    font-size: 16px;
    font-family: 'Inter Variable', sans-serif;
    line-height: 1.3;
    letter-spacing: -0.0075rem;
}
.video-play-button{
   
    left: -13%;
  
}
}
</style>