<template>
    
   <section class="socialproof">
    <section class="testimonials">
        
        <h1>Testimonials</h1>
        <div class="bigcontainer">
          <div class="container">
            <div class="slider">
              <section>
                <div class="section-content">
                  <img src="../assets/sav.png" alt="" />
                  <p>“Over the years, Kpododo Consult has been an invaluable partner. Their innovative and user-friendly communication technologies have revolutionized our work in communities, making it accessible to everyone at the last mile. Kpododo Consult has also consistently supported us in all our ICT4D initiatives with scalable and cutting-edge ICT SBC interventions, including setting up our famed SHE+ helpline to support the delivery of reproductive health services to Ghanaians.”</p>
                  <h2>Ethel Emefa Ehla<br> <span>Gender &amp; Social Inclusion and Partnership Manager</span></h2>
                 
                </div>
              </section>
              <section>
                <div class="section-content">
                  <img src="../assets/ox.png" alt="" />
                  <p> “Oxfam over the past two years has contracted Kpododo consult for some of their visibility materials and would like to recommend them to any entity interested in such services. They do wonderful work on all visibility materials eg pull up banners, branded T' shirts, cups etc. Go in for their services and you will never regret it.” </p>
                  <h2>Fauziatu Abdul - Rahman<br> <span>Project Coordinator (Power to Choose Project)</span></h2>
                </div>
              </section>
              <section>
                <div class="section-content">
                  <img src="../assets/csd.png" alt="" />
                  <p>"Kpododo Consult exceeded my expectations! The quality and speed of delivery were exceptional. They've become our go-to for all production needs."</p>
                  <h2>David Issaka<br><span>Executive Director </span></h2>
                </div>
              </section>
             <!---- <section>
                <div class="section-content">
                  <img src="../assets/logo.png" alt="" />
                  <p>‘’My experience with Kpododo consult so far has been great. 
                      Everything is easy with data collection, from creating visualization, 
                      scheduling, collaboration and many more"</p>
                  <h2>Stephen Agbenyo <br><span>Director</span></h2>
                </div>
              </section>
              <section>
                <div class="section-content">
                  <img src="../assets/logo.png" alt="" />
                  <p>‘’My experience with Kpododo consult so far has been great. 
                      Everything is easy with data collection, from creating visualization, 
                      scheduling, collaboration and many more"</p>
                  <h2>Stephen Agbenyo <br><span>Director</span></h2>
                </div>
              </section>
            -->
            </div>
            
              <div class="hmarrows">
                <div class="arrow left">
                <i class='fas fa-angle-left'></i>
                </div>
                <div class="arrow right">
                  <i class='fas fa-angle-right'></i>
                </div>
              </div>
                
            </div>
        
          </div>
         
          <div class="menu">
              <span class="menu-item active" data-index="0"></span>
              <span class="menu-item" data-index="1"></span>
              
            </div>
         
        
        
  </section>

    <section class="partners">
    <h1>Our Partners</h1>
    <logo-slider>

        <div>
            <img src="../assets/partners/fhi.png">
            <img src="../assets/partners/sav.jpg">
            <img src="../assets/partners/gl.jpg">
            <img src="../assets/partners/GHS.jpg">
            <img src="../assets/partners/savana-signatures.png">
            <img src="../assets/partners/norsac.png">
            <img src="../assets/partners/wfp.png">
            <img src="../assets/partners/asbc.jpg">
            <img src="../assets/partners/csd.png">
            <img src="../assets/partners/gh.png">
            <img src="../assets/partners/coginta.png">
        </div>
        <div>
            <img src="../assets/partners/fhi.png">
            <img src="../assets/partners/sav.jpg">
            <img src="../assets/partners/gl.jpg">
            <img src="../assets/partners/GHS.jpg">
            <img src="../assets/partners/savana-signatures.png">
            <img src="../assets/partners/norsac.png">
            <img src="../assets/partners/wfp.png">
            <img src="../assets/partners/asbc.jpg">
            <img src="../assets/partners/csd.png">
            <img src="../assets/partners/gh.png">
            <img src="../assets/partners/coginta.png">

            </div>
        
        </logo-slider>
    </section>
   </section>
  </template>
  
  <script>

  
  export default {
    mounted() {
        const slider = document.querySelector('.slider');
const menuItems = document.querySelectorAll('.menu-item');
const leftArrow = document.querySelector('.arrow.left');
const rightArrow = document.querySelector('.arrow.right');

let currentSectionIndex = 0;
let sectionCount = menuItems.length;

// hide left arrow on initial load
leftArrow.style.display = 'none';


menuItems.forEach((item, index) => {
  item.addEventListener('click', () => {
    moveSliderToSection(index);
  });
});

// add event listeners to arrow buttons
leftArrow.addEventListener('click', () => {
  moveSliderToSection(currentSectionIndex - 1);
});

rightArrow.addEventListener('click', () => {
  moveSliderToSection(currentSectionIndex + 1);
});

// add touch/swipe support to slider
let touchStartX = 0;
let touchEndX = 0;

slider.addEventListener('touchstart', (e) => {
  touchStartX = e.touches[0].clientX;
});

slider.addEventListener('touchmove', (e) => {
  touchEndX = e.touches[0].clientX;
});

slider.addEventListener('touchend', () => {
  if (touchEndX < touchStartX) {
    moveSliderToSection(currentSectionIndex + 1);
  }

  if (touchEndX > touchStartX) {
    moveSliderToSection(currentSectionIndex - 1);
  }
});

document.addEventListener('keydown', (event) => {
  if (event.key === 'ArrowLeft') {
    moveSliderToSection(currentSectionIndex - 1);
  } else if (event.key === 'ArrowRight') {
    moveSliderToSection(currentSectionIndex + 1);
  }
});


function moveSliderToSection(index) {
  // make sure index is within range of sections
  if (index < 0 || index >= sectionCount) {
    return;
  }

  // calculate the distance to move the slider
  const distance = index * -130;

  // move the slider
  slider.style.transform = `translateX(${distance}%)`;

  // remove 'active' class from all menu items
  menuItems.forEach((item) => {
    item.classList.remove('active');
  });

  // add 'active' class to clicked item
  menuItems[index].classList.add('active');

  // update current section index
  currentSectionIndex = index;

  // hide/show arrow buttons as needed
  if (currentSectionIndex === 0) {
    leftArrow.style.display = 'none';
  } else {
    leftArrow.style.display = 'block';
  }

  if (currentSectionIndex === sectionCount - 1) {
    rightArrow.style.display = 'none';
  } else {
    rightArrow.style.display = 'block';
  }
}


    }
}






  
  </script>


<style scoped>




.hmarrows{
  display: none;
}

.testimonials{
    background-color: #FFFFFF;

   
    padding-bottom: 50px;

}

.testimonials h1{
    color: #425466;
    font-size:36px;
    font-family: 'Inter Variable', sans-serif;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: -0.0525rem;
    text-align: center; 
}


.bigcontainer {
    width: 100%;
   
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    width: 100%;
   
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .slider {
    display: flex;
    height: 100%;
   width: 100%;
    flex-shrink: 0;
    transition: all 0.8s;
  }
  
  .slider section {
    flex-basis: 20%;
    width: 20%;
    flex-shrink: 0;
    flex: 1;
    margin-right: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
    background-color: #FBFBFD;
    border-radius: 5px;
    opacity: 0.8;
    font-family:"Work Sans"
  }
  .section-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    align-self: center;
    height: 550px;
  }
  .section-content p{
    color: #0A2540;
font-size: 16px;
font-family: 'Inter Variable', sans-serif;
line-height: 1.7;
letter-spacing: -0.02344rem;
text-align: left;
  }
  .section-content h2{
    color: #0A2540;
font-size: 18px;
font-family: 'Inter Variable', sans-serif;
font-weight: 600;
line-height: 1.7;
letter-spacing: -0.02344rem;
text-align: left;
  }

  .section-content h2 span{
    color: #0A2540;
font-size: 18px;
font-family: 'Inter Variable', sans-serif;
line-height: 1.7;
letter-spacing: -0.02344rem;
font-weight: 300;
text-align: left;
  }


  .section-content img {
    max-height: 95px;
  
  }
  
  
  
  .menu {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .menu-item {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
  }
  
  .menu-item.active {
    border-radius: 22px;
    background: #F24E1E;
  }
  
  /* Arrows */
  .arrow {
  position: absolute;
 display: flex;
 align-self: center;
 align-items: center;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.arrow.left {
  
  border: 2px solid black;
  border-radius: 20px;
  display: flex ;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.fas.fa-angle-left {
 display: flex;
 justify-content: center;
 align-items: center;
 align-self: center;
}

.fas.fa-angle-right {
  display: flex;
 justify-content: center;
 align-items: center;
 align-self: center;
}

.arrow.right {
  border: 2px solid black;
  border-radius: 20px;
  right: 5%;
  display: flex ;
    align-items: center;
    align-self: center;
    justify-content: center;
}
  @media (max-width: 1240px) {
    .slider {
    display: flex;
    height: 100%;
   width: 100%;
    flex-shrink: 0;
    transition: all 0.8s;
  }
  }
  
  @media (max-width: 768px) {
    .section-content p {
      font-size: 12px;
      line-height: 1.5rem;

    }
    .section-content h2 {
      font-size: 14px;
    }
    .bigcontainer {
      width: 100%;
    padding: 1rem;
    }
    .section-content h2 span {
        font-size: 14px;
      }
      .section-content{
     
        gap: 10px;
      
      }
  }
  
  @media (max-width: 480px) {
    .bigcontainer {
    width: 100%;
    padding: 1rem;
    }
    .section-content img {
    margin-bottom: 20px;
    }
    .section-content 
    p {
      font-size: 10px;
      line-height: 1.5rem;
    }
    .section-content h2 {
      font-size: 14px;
    }
    .section-content h2 span {
        font-size: 14px;
      }
    .arrow {
      display: none  !important;
    }
    .section-content{
     
        gap: 10px;
      
      }
      

.arrow.left {
  
  display: none;
}

.fas.fa-angle-left {
  display: none;
}

.fas.fa-angle-right {
  display: none;
}

.arrow.right {
 display: none;
}
  }








.socialproof{
    padding-top: 50px;
    padding-bottom: 50px;
}

.partners{
    background-color: #FFFFFF;
}

.partners h1{
    text-align: center;
    color: #425466;
text-align: center;
font-size: 36px;
font-family: Inter;
font-weight: 400;
line-height: 4.5rem;
letter-spacing: -0.0525rem;
}

  logo-slider {
    --image-size: 200px;
  
    padding: 20px;
    overflow: hidden;
 
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
  }
  logo-slider:hover div {
    animation-play-state: paused;
  }
  logo-slider div {
    display: flex;
    position: relative;
    animation: marquee 30s linear infinite;
    justify-content: space-around;
  }
  logo-slider img {
    display: block;
    min-width: 250px;
    height: 200px;
    margin: 0 1vw;
  }
  logo-slider:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 80px, rgba(255, 255, 255, 0) calc(100% - 80px), white);
  }
  @media (max-width: 900px) {
    logo-slider {
      --image-size: 50px;
      --image-size: min(max(50px, 10vw), 100px);
    }
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }


  @media screen and (max-width: 970px) {
    .socialproof {
    padding-top: 50px;
    padding-bottom: 50px;
}
.slider section {
    padding-left: 0;
}
.section-content p {
    font-size: 12px;
    line-height: 1.5rem;
    width: 100vw;
    padding-right: 2rem;
}
.slider {
    display: flex;
    height: 100%;
    width: auto;
    flex-shrink: 0;
    transition: all 0.8s;
}
.testimonials h1{
 
    font-size: 36px;
    
    font-weight: 600;
    
    margin: 0;
}
.partners h1[data-v-2bcd0097] {
    text-align: center;
    color: #425466;
    text-align: center;
    font-size:36px;
    margin: 0;
}
  }


</style>