<template>
    <footer>
      
      <div class="dv">
       <router-link :to="{name:'home'}"><img src="../assets/logo.png" alt=""></router-link>
          
      </div>
      <ul>
          Company
          <router-link :to="{name:'about'}"><li>About Us</li></router-link>
           <router-link :to="{name:'contact'}"><li>Contact Us</li></router-link>
          <router-link :to="{name:'maternal'}"><li>Case Studies</li></router-link>
           <router-link :to="{name:'maternal'}"><li>Blog</li></router-link>
          
      </ul>
      <ul>
          Product & Services
          <router-link :to="{name:'bulkSMS'}"><li>Bulk SMS</li></router-link>
               <router-link :to="{name:'ivr'}"><li>Interactive Voice Response</li></router-link>
               <router-link :to="{name:'videoproduction'}"><li>Video Production</li></router-link>
               <router-link :to="{name:'cinemavan'}"><li>Cinema Van</li></router-link>
               <router-link :to="{name:'datacollection'}"><li>Kpododo Collect</li></router-link>
               <router-link :to="{name:'kpododoprint'}"><li>Kpododo Prints</li></router-link>
      </ul>
      <ul>
       Follow us:
          <div class="sm">
           <li><a href="https://www.facebook.com/kpododoConsult"><img src="../assets/footer/logos_facebook.svg" alt=""></a></li>
               <li><a href="https://twitter.com/kpododoC"><img src="../assets/footer/devicon_twitter.svg" alt=""></a></li>
               <li><a href="https://www.linkedin.com/company/kpododo-consult"><img src="../assets/footer/devicon_linkedin.svg" alt=""></a></li>
               <li><a href="https://www.instagram.com/kpododoprints"><img src="../assets/footer/skill-icons_instagram.svg" alt=""></a></li>
          </div>
          
          
      </ul>
      


     
  </footer>
  <div class="lowerfooter">
       <h3>© 2023 Kpododo Consult. All Rights Reserved</h3>
       

   </div>
</template>

<style scoped>
.lowerfooter{
   display: flex;
   justify-content: center;
   gap: 100px;
   width: 100%;
   align-items: center;
   align-self: center;
  
}
.lowerfooter h3{
   font-family: 'Inter Variable', sans-serif;
   font-weight: 400;
   font-size: 16px;
}

footer{
 display: flex;
 justify-content: space-between;
 padding: 2rem 7rem;
background-color: rgba(250, 235, 215, 0.295);
}
footer li{
 padding-top: 1rem;
 font-family: var(--Primaryfont);
 color: var(--secondaryText);
 font-weight: 300;
list-style-type: none;
 
}

footer ul{
 font-family: var(--Primaryfont);
 color:#242526;
 font-weight: 700;
text-align: left;
 
}
footer .dv{
 display: block;
 width: 300px;
 text-align: left;
}
footer .sm img{
 padding-top: 1rem;
 width: 1.5rem;
 color: var(--secondaryText);
}
footer  a{
 
 color: #4A4A68;
}
footer .ci img{
 padding-top: 1.5rem;
 width: 1rem;
 color: var(--secondaryText);
}
footer  .sm {
 display: grid;
 justify-content: center;
 grid-template-columns: repeat(4, 1fr);
 gap: 10px;

}

footer  .ci {
 display: grid;
 justify-content: center;
 grid-template-columns: repeat(1, 1fr);
 gap: 10px;

}
footer .dv img{
 width: 20rem;
 
}
footer .dv p{
 font-family: var(--Primaryfont);
 color: var(--secondaryText);
 font-weight: 300;
 padding-top: 1rem;
 text-align: left;
 margin: 0;
}
footer a {
 text-decoration: none;
 color: #242526;
 cursor: pointer;
}

/*media query for nav*/
@media screen and (max-width: 970px) {
  
   footer ul {
 padding-left: 0;
 margin-top: 2rem;
}
footer {
 display: block;
 justify-content: space-between;
 margin: 1rem;
 padding: 1rem;
}
  }
</style>