import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/company/AboutView.vue')
  }
  ,
  {
    path: '/bulkSMS',
    name: 'bulkSMS',
    component: () => import('../views/BulkSMS.vue')
  }
  ,
  {
    path: '/ivr',
    name: 'ivr',
    component: () => import('../views/ivr.vue')
  }
  ,
  {
    path: '/datacollection',
    name: 'datacollection',
    component: () => import('../views/datacollection.vue')
  }
  ,
  {
    path: '/cinemavan',
    name: 'cinemavan',
    component: () => import('../views/cinemavan.vue')
  }
  ,
  {
    path: '/ict4d',
    name: 'ict4d',
    component: () => import('../views/ict4d.vue')
  }
  ,
  {
    path: '/kpododoprint',
    name: 'kpododoprint',
    component: () => import('../views/kpododoprint.vue')
  }
  ,
  {
    path: '/videoproduction',
    name: 'videoproduction',
    component: () => import('../views/videoproduction.vue')
  }
  ,
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  }
  ,
  {
    path: '/provescritical',
    name: 'provescritical',
    component: () => import('../views/caseStudies/ProvesCritical.vue')
  }
  ,
  {
    path: '/maternal',
    name: 'maternal',
    component: () => import('../views/caseStudies/maternal.vue')
  }
  ,
  {
    path: '/schoolClosed',
    name: 'schoolClosed',
    component: () => import('../views/caseStudies/schoolClosed.vue')
  }
  ,
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/company/solution.vue')
  }
  ,
  {
    path: '/career',
    name: 'career',
    component: () => import('../views/company/career.vue')
  }
  ,
  {
    path: '/hiring-details',
    name: 'hiring-details',
    component: () => import('../views/company/hiring-details.vue')
  }
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
