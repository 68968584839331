<template>
    <section id="casestudies">
        <div class="development" >
            <h1>Case Studies & Impact Stories</h1>
            <div class="scrolly">
       <!---
       <button id="left"><span class="material-icons">chevron_left</span></button>
       <button id="right"><span class="material-icons">chevron_right</span></button>-->
   <ul class="carouselPre">
    <router-link :to="{name:'provescritical'}">
       <li class="itemsC" @mousemove="updateImgContentPosition">
           <div class="contents">
               
               <div class="textDet" style="background-color: #303030">
                
                    <p>Kpododo Mobile Messaging Platform 
                    Campaign Proves Critical in Ghana's 
                    Fight Against COVID-19</p>
                
            </div>
           </div>
           <div class="bgImg">
            <figcaption class="img-content" >
                    <h2 class="title">Read More</h2>
                    
                  </figcaption>
                  <span class="img-content-hover"  >
                    <h2 class="title">Read More</h2>
                    
                  </span>
            <img src="../assets/home/Rectangle7.png" alt="image" srcset="">
          </div>
       </li>
      </router-link>
       <router-link :to="{name:'schoolClosed'}">
       <li class="itemsC" @mousemove="updateImgContentPosition">
           <div class="contents">
               
               <div class="textDet" style="background-color: #303030">
                
                    <p>How Kpododo Audio/Bulk SMS - Promoting psychosocial resilience for parents and teachers during COVID-19 school closures in Ghana.</p>
                
            </div>
           </div>
           <div class="bgImg">
            <figcaption class="img-content">
                    <h2 class="title">Read More</h2>
                    
                  </figcaption>
                  <span class="img-content-hover" >
                    <h2 class="title">Read More</h2>
                    
                  </span>
            <img src="../assets/home/IMG_2485.jpg" alt="image" srcset="">
          </div>
       </li>
      </router-link>
       <router-link :to="{name:'provescritical'}">
       <li class="itemsC" @mousemove="updateImgContentPosition">
           <div class="contents">
               
               <div class="textDet" style="background-color: #303030">
                
                <p>Kpododo Mobile Messaging Platform 
                   Campaign Proves Critical in Ghana's 
                   Fight Against COVID-19</p>
               
                </div>
           </div>
           <div class="bgImg">
            <figcaption class="img-content">
                    <h2 class="title">Read More</h2>
                    
                  </figcaption>
                  <span class="img-content-hover" >
                    <h2 class="title">Read More</h2>
                    
                  </span>
            <img src="../assets/home/IMG_2954.jpg" alt="image" srcset="">
          </div>
       </li>
      </router-link>
       <router-link :to="{name:'maternal'}">
       <li class="itemsC" @mousemove="updateImgContentPosition">
           <div class="contents">
              
               <div class="textDet" style="background-color: #303030">
                
                <p>Using voice messages & SMS to improve Maternal and Child Health in Ghana</p>
            
            </div>
           </div>
           <div class="bgImg">
            <figcaption class="img-content">
                    <h2 class="title">Read More</h2>
                    
                  </figcaption>
                  <span class="img-content-hover" >
                    <h2 class="title">Read More</h2>
                    
                  </span>
            <img src="../assets/home/Rectangle.png" alt="image" srcset="">
          </div>
       </li>
      </router-link>

   </ul>
</div>
            </div>
       
    </section>
 

</template>




<script>
export default {
  methods: {
    updateImgContentPosition(event) {
      const imgContentHover = event.currentTarget.querySelector('.img-content-hover');
      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      imgContentHover.style.transform = `translate(${x}px, ${y}px)`;
    }
  }
};
</script>













 








<style scoped>
    
    .bgImg {
  
  cursor: pointer;
  overflow: hidden;
}
.bgImg:hover .img-content-hover {
  display: block;
}

.textDet{
   
   z-index: 10;
 }

 img {
   width: 100%;

   -o-object-fit: cover;
      object-fit: cover;
   transform: scale(1);
   transition: all 0.3s ease-in-out;
 }
 img:hover {
   transform: scale(1.05);
 }

.img-content-hover {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  display: none;
  padding: 0.5rem;
  background:  #F48116;

  margin-top: 1.25rem;
  margin-left: -2rem;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 9px;


color: #FFFFFF;
}

.img-content {
  display: none;
}

#casestudies{

}
.development ::-webkit-scrollbar{
        height: 4px;
        width: 4px;
        background: gray;
    }
   .development ::-webkit-scrollbar-thumb:horizontal{
        background: #000;
        border-radius: 10px;
    }

.cse{
  
}    .development{
    background: #FAFCFE;
    display: block;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: auto !important; /* or overflow-x: scroll; */
    padding-left: 16px;
  }


  .development h1{
    text-align: center;
    font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 72px;
/* or 129% */
padding-bottom: 50px;
letter-spacing: -0.015em;

margin: 0;
color: #425466;

  }
  
  .mandatory {
  
    overflow-y: hidden;
   
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    display: flex;
  gap: 4px;
  }
 
  .mandatory .crd {
    
 
   
    border-radius: 16px;
    /* Styles No Requireds */
    
    scroll-snap-align: start;
   gap: 25px;
    display: flex;
    flex: none;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 1rem;
    padding-bottom: 50px;
  }
  .mandatory .crd p{
    font-family: 'Inter Variable', sans-serif;
font-style: normal;
text-align: left;
font-weight: 400;
font-size: 16px;
line-height: 1.7;
/* or 125% */

letter-spacing: -0.015em;
margin: 0;
color: #FFFFFF;
  }
  .mandatory .crd a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
color: #FFFFFF;
text-align: left;
font-family: 'Inter Variable', sans-serif;

  }

  /* Styles No Requireds */
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 970px) {
    .textDet{
   
   z-index: 10;
 }
 img {
   width: 100%;

   -o-object-fit: none;
      object-fit: none;
   transform: none;
   transition: all 0.3s ease-in-out;
 }
 img:hover {
   transform: none;
 }
    .development {
        background: #FAFCFE;
        padding-left: 1rem;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-right: 0px;
      
    }
    .development h1 {
  
    font-size: 36px;
    line-height: 52px;
    padding-bottom: 15px;
    letter-spacing: -0.015em;
    margin: 0;
    color: #425466;
}
.mandatory .crd {
  padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 50px;
}
.mandatory .crd p[data-v-5d2345cd] {
    width: auto;
    height: auto;
}
.one {
   
    width: 300px;
    height: 400px;
}
.two {
   
   width: 300px;
   height: 400px;
}
.three {
   
   width: 300px;
   height: 400px;
}
.four {
   
   width: 300px;
   height: 400px;
}
}













a{
        text-decoration: none;
        color: white;
    }
.carouselPre{
    grid-auto-flow: column;
    display: grid;
    grid-auto-columns: calc((100% - 3 *  20px)/ 4);
    grid-column-gap: 20px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    list-style: none;
    scroll-behavior: smooth;
    padding: 0;
}

#title{
    margin: 10px 10px 10px 0;
    font-size: 34px;
    font-weight: 600;
}

.topT{
    border-top: 1px solid rgb(212, 212, 212);
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

.itemsC .textDet{
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
}

.contents{
    color: white;
}

.itemsC .contents p{    
    -webkit-line-clamp: 3;
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 400;
    width: 90%;
    margin: 1rem;
}

.leftTxtDet{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 33px;
}

.itemsC{
    position: relative;
}

.scrolly{
    overflow: hidden;
}

.carouselPre li{
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
    background-color: royalblue;
    list-style: none;
    border-radius: 20px;
    overflow: hidden;
}

.carouselPre li .bgImg{
    width: 100%;
    height: 100%;
}

.carouselPre li img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scrolly{
    position: relative;
}

#left,#right{
    background-color: rgba(255, 255, 255, 0.9);
    outline: none;
    border: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    transform: translate(0,-60%);
    cursor: pointer;
    z-index: 2;
}

#left{
    left: 0;
}

#right{
    right: 0;
}


@media only screen and (max-width: 890px) {
    .carouselPre {
        grid-auto-columns: calc((100% - 2 * 20px)/ 3);
        height: 36rem;
    }
  }

@media only screen and (max-width: 660px) {
    .carouselPre {
        grid-auto-columns: calc((100% - 1 * 20px)/ 2);
        height: 36rem;
    }
  }

@media only screen and (max-width: 440px) {
    .carouselPre {
        grid-auto-columns: 100%;
        height: 36rem;
    }
  }


::-webkit-scrollbar {    
  width: 15px;
height: 15px;
border-left: #ededed solid 1px;
background-color: #fcfcfc;
border-top: #ededed solid 1px;
    }

::-webkit-scrollbar-thumb:hover{
cursor: pointer;
background: #c7c7c7;
width: 15px;
    background-clip: content-box;
    border: 4px solid transparent;
border-radius: 10px;
}

    ::-webkit-scrollbar-button {   
 display: none;
    }

    ::-webkit-scrollbar-thumb {
background: #dbdbdb;
    background-clip: content-box;
    border: 4px solid transparent;
border-radius: 10px;

    }


</style>