import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@fontsource-variable/inter';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'remixicon/fonts/remixicon.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import VueGtag from "vue-gtag";

createApp(App).use(VueGtag, {
  config: { id: "G-8QNQX23DTT" }
}).mount("#app");


createApp(App).use(AOS.init()).use(router).mount('#app')
