<template>
    <section class="banner">
    <img src="../assets/home/KpododoWEbSITE2.jpg" alt="">
    <div class="bannertext">
        <h1>Let’s Connect</h1>
        <p>Curious about how Kpododo 
            can support your business to achieve it’s 
            goals?</p>
            <router-link :to="{name:'contact'}"><a href="">Get in Touch</a></router-link>
    </div>
</section>
</template>
<script></script>
<style scoped>


.banner{
    display: flex;
    justify-content: center;
   padding: 50px;
    background-color: #FFFFFF;
    gap: 50px;
   
  }

  .banner img{
    width: 30rem;

  }
.bannertext{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20.5px;
    width: 38rem;
}
  .banner h1{
    color: #0E0E2C;
font-size: 36px;
font-weight: 400;
font-family: 'Inter Variable', sans-serif;

letter-spacing: -0.08719rem;
margin: 0;
text-align: left;
  }
  .bannertext p{
    color: #0A2540;
font-size: 16px;
font-family: 'Inter Variable', sans-serif;
line-height: 30px;
font-weight: 400;
letter-spacing: -0.03rem;
text-align: left;
margin: 0;
  }

  .bannertext a{
    text-decoration: none;
    color: #FFF;
font-size: 16px;
font-family: 'Inter Variable', sans-serif;
line-height: 3.25rem;
letter-spacing: -0.0225rem;
    display: flex;
width: 14.0625rem;
height: 4.5rem;
padding: 0.625rem 2.625rem 0.625rem 2.6875rem;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
background: linear-gradient(265deg, #F24E1E 0%, #F48116 100%);
  }


  @media screen and (max-width: 970px) {
    .banner {
        display: flex;
        justify-content: center;
        gap: 100px;
        padding-top: 50px;
    padding-bottom: 50px;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
    .banner img {
        width: 100vw;
        height: auto;
    }
    .bannertext {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10.5px;
        width: auto;
        text-align: center; 
        align-items: center;
    }
    .bannertext p {
        padding: 1rem;
        
        text-align: center;
      
    }
    .bannertext h1 {
        padding: 1rem;
        font-size: 36px;
        text-align: center;
      margin: 0;
    }
    .banner {
   
    padding-top: 50px;
    padding-bottom: 50px;
}
.bannertext p[data-v-37aed33c] {
    
    line-height: 1.7;
    
    margin: 0;
}
}

</style>