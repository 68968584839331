<template>
    
    <div class="scrolly">
       <!---
       <button id="left"><span class="material-icons">chevron_left</span></button>
       <button id="right"><span class="material-icons">chevron_right</span></button>-->
   <ul class="carouselPre">
       <li class="itemsC">
           <div class="contents">
               
               <div class="textDet" style="background-color: #51507c">
                <router-link :to="{name:'provescritical'}">
                    <p>Kpododo Mobile Messaging Platform 
                    Campaign Proves Critical in Ghana's 
                    Fight Against COVID-19</p>
                </router-link>
            </div>
           </div>
           <div class="bgImg"><img src="../assets/home/Rectangle7.png" alt="image" srcset=""></div>
       </li>
       <li class="itemsC">
           <div class="contents">
               
               <div class="textDet" style="background-color: #51507c">
                <router-link :to="{name:'schoolClosed'}">
                    <p>How Kpododo Audio/Bulk SMS - Promoting psychosocial resilience for parents and teachers during COVID-19 school closures in Ghana.</p>
                </router-link>
            </div>
           </div>
           <div class="bgImg"><img src="../assets/home/IMG_2485.jpg" alt="image" srcset=""></div>
       </li>
       <li class="itemsC">
           <div class="contents">
               
               <div class="textDet" style="background-color: #51507c">
                <router-link :to="{name:'provescritical'}">
                <p>Kpododo Mobile Messaging Platform 
                   Campaign Proves Critical in Ghana's 
                   Fight Against COVID-19</p>
                </router-link>
                </div>
           </div>
           <div class="bgImg"><img src="../assets/home/IMG_2954.jpg" alt="image" srcset=""></div>
       </li>
       <li class="itemsC">
           <div class="contents">
              
               <div class="textDet" style="background-color: #51507c">
                <router-link :to="{name:'maternal'}">
                <p>Using voice messages & SMS to improve Maternal and Child Health in Ghana</p>
            </router-link>
            </div>
           </div>
           <div class="bgImg"><img src="../assets/home/Rectangle.png" alt="image" srcset=""></div>
       </li>
       

   </ul>
</div>
</template>

<style scoped>
     a{
        text-decoration: overline;
        color: white;
    }
.carouselPre{
    grid-auto-flow: column;
    display: grid;
    grid-auto-columns: calc((100% - 3 *  20px)/ 4);
    grid-column-gap: 20px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    list-style: none;
    scroll-behavior: smooth;
    
}

#title{
    margin: 10px 10px 10px 0;
    font-size: 34px;
    font-weight: 600;
}

.topT{
    border-top: 1px solid rgb(212, 212, 212);
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

.itemsC .textDet{
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
}

.contents{
    color: white;
}

.itemsC .contents p{    
    -webkit-line-clamp: 3;
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 400;
    width: 90%;
    margin: 1rem;
}

.leftTxtDet{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 33px;
}

.itemsC{
    position: relative;
}

.scrolly{
    overflow: hidden;
}

.carouselPre li{
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
    background-color: royalblue;
    list-style: none;
    border-radius: 20px;
    overflow: hidden;
}

.carouselPre li .bgImg{
    width: 100%;
    height: 100%;
}

.carouselPre li img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scrolly{
    position: relative;
}

#left,#right{
    background-color: rgba(255, 255, 255, 0.9);
    outline: none;
    border: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    transform: translate(0,-60%);
    cursor: pointer;
    z-index: 2;
}

#left{
    left: 0;
}

#right{
    right: 0;
}


@media only screen and (max-width: 890px) {
    .carouselPre {
        grid-auto-columns: calc((100% - 2 * 20px)/ 3);
        height: 23rem;
    }
  }

@media only screen and (max-width: 660px) {
    .carouselPre {
        grid-auto-columns: calc((100% - 1 * 20px)/ 2);
        height: 25rem;
    }
  }

@media only screen and (max-width: 440px) {
    .carouselPre {
        grid-auto-columns: 100%;
        height: 25rem;
    }
  }


::-webkit-scrollbar {    
  width: 15px;
height: 15px;
border-left: #ededed solid 1px;
background-color: #fcfcfc;
border-top: #ededed solid 1px;
    }

::-webkit-scrollbar-thumb:hover{
cursor: pointer;
background: #c7c7c7;
width: 15px;
    background-clip: content-box;
    border: 4px solid transparent;
border-radius: 10px;
}

    ::-webkit-scrollbar-button {   
 display: none;
    }

    ::-webkit-scrollbar-thumb {
background: #dbdbdb;
    background-clip: content-box;
    border: 4px solid transparent;
border-radius: 10px;

    }



</style>