<template>
  <Navbar/>
  <section>
    <div class="landingPage">
        <div class="landingDetails" >
            <h1 data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">YOU ARE ON A JOURNEY  TO CHANGE THE WORLD</h1>
            <p>
                You have the ideas. <br> We have the tools to see you through.
            </p>
            <div class="button">
                <a href="#whatweoffer">Get Started</a>
            </div>
        </div>
        <Play/>
    </div>
  </section>
  <section class="about"  data-aos="fade-right" data-aos-duration="900">
        <h1>We believe in a world where <span> information flows without boundaries</span></h1>
        <div class="whoweare">
            <div class="whotext">
                <h1>Who We Are</h1>
                <h3>TECHNOLOGY + HUMAN INGENUITY</h3>
                <p>We are a leading provider of tech-powered solutions that engineer social change and improve development outcomes.

As a social enterprise, we are committed to human-centered designs that prioritize communities and understand the contexts surrounding their challenges.</p>
                <router-link :to="{name:'about'}"> <a href="">Learn More</a></router-link>
            </div>
            <div class="whoImage" data-aos="fade-left" data-aos-duration="900">
                <img src="../assets/home/Group92.jpg" alt="">
            </div>
        </div>
    </section>
    <Whatwedo data-aos="zoom-in-up" data-aos-duration="900"/>
    <section class="whatweoffer" id="whatweoffer" >
        <div class="offertext" data-aos="zoom-in-up" data-aos-duration="900">
            <h1>What We Offer</h1>
            <p>A DIGITAL LEAP FORWARD <br>
                At Kpododo Consult, we support humanitarian, civic and public service activities.We extend the reach of organisations beyond borders with our digital media and technology.</p>
        </div>
        <div class="offercards">
            <div class="offercard">
                <router-link :to="{name:'bulkSMS'}">
                <div class="card" data-aos="fade-right" data-aos-duration="1500">
                    <img src="../assets/home/sms.svg" alt="">
                    <h3>Bulk SMS</h3>
                    <P>Our highly customizable messaging platform ensures easy access to information for both rural and urban communities through SMS.</P>
                </div>
                </router-link>
                <router-link :to="{name:'ivr'}">
                <div class="card">
                    <img src="../assets/home/ivr.svg" alt="">
                    <h3>Interactive Voice Responses</h3>
                    <P>Our voice-powered technology interacts with callers, disseminates information, and routes calls efficiently, thus providing pre-recorded voice responses and access to relevant data.</P>
                </div>
                </router-link>
                <router-link :to="{name:'videoproduction'}">
                <div class="card" data-aos="fade-left" data-aos-duration="1500">
                    <img src="../assets/home/video.svg" alt="">
                    <h3>Video Production</h3>
                    <P>We create content informed by behavioral change research that resonates with individuals, captures their attention, and motivates them to take action.</P>
                </div>
                </router-link>
                <router-link :to="{name:'cinemavan'}">
                <div class="card" data-aos="fade-right" data-aos-duration="1500">
                    <img src="../assets/home/cinema.svg" alt="">
                    <h3>Cinema Vans</h3>
                    <P>Our cinema vans offer a versatile tool for screening videos, engaging communities, promoting behavior change, and fostering positive social outcomes.</P>
                </div>
                </router-link>
                <router-link :to="{name:'datacollection'}">
                <div class="card">
                    <img src="../assets/home/data.svg" alt="">
                    <h3>Digital Data Collection & Analysis</h3>
                    <P>Kpododo Collect is a digital platform that simplifies data collection, enhances data quality, and expedites analysis, leading to more informed decision-making.</P>
                </div>
                </router-link>
                <router-link :to="{name:'kpododoprint'}">
                <div class="card" data-aos="fade-left" data-aos-duration="1500">
                    <img src="../assets/home/uil_print.svg" alt="">
                    <h3>Digital Printing</h3>
                    <P> We specialize in top-quality digital printing services that empower organizations to create impactful printed materials for behavior change.</P>
                </div>
                </router-link>
            </div>
        </div>
    </section>
    <section class="wherewework">
        <div class="whereweworktext" data-aos="zoom-in-up" data-aos-duration="900">
            <h1>Where We work</h1>
            <p>Our technology is borderless and 
                our expertise cuts across sectors</p>
        </div>
        <div class="whereweworkcards">
            <div class="whereweworkrcard">
                <div class="card" data-aos="fade-right" data-aos-duration="1500">
                    <img src="../assets/home/agriculture.svg" alt="">
                    <h3>Agriculture</h3>
                </div>
                <div class="card">
                    <img src="../assets/home/health.svg" alt="">
                    <h3>Health</h3>
                </div>
                <div class="card" data-aos="fade-left" data-aos-duration="1500">
                    <img src="../assets/home/education.svg" alt="">
                    <h3>Education</h3>
                </div>
                <div class="card" data-aos="fade-right" data-aos-duration="1500">
                    <img src="../assets/home/governance.svg" alt="">
                    <h3>Governance &
                        Democracy</h3>
                </div>
                <div class="card">
                    <img src="../assets/home/research.svg" alt="">
                    <h3>Research</h3>
                </div>
                <div class="card" data-aos="fade-left" data-aos-duration="1500">
                    <img src="../assets/home/climate.svg" alt="">
                    <h3>Climate</h3>
                </div>
            </div>
        </div>
        <div class="learnmore">
            <router-link :to="{name:'solution'}"> 
            Learn More
            </router-link>
        </div>
    </section>
    <section class="Ourimpacts">
        <h1>Our Impact <br>
            in Numbers</h1>
        <div class="pacts">
            <div class="ourImpact">
            <div class="impactCard">
                <h2>19.7 MILLION+</h2>
                <P>SMS delivered</P>
            </div>
            <div class="impactCard">
                <h2>16.5 k+</h2>
                <P>Voice Messages delivered</P>
            </div>
            <div class="impactCard">
                <h2>2.5 k+</h2>
                <P>Data Subscribers</P>
            </div>
            <!----
            <div class="impactCard">
                <h2>22.3 MILLION+</h2>
                <P>Messages delivered</P>
            </div>-->
            </div>
            <div class="map">
                <img src="../assets/home/impact.svg" alt="">
            </div>
        </div>

    </section>
    <ImpactStories/>
    <Social/>
    <Banner/>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/navbar.vue'
import Whatwedo from '@/components/whatwedo.vue'
import ImpactStories from '@/components/impactStories.vue'
import Stories from '@/components/stories.vue'
import Social from '@/components/socialproof.vue'
import Banner from '@/components/banner.vue'
import Footer from '@/components/footer.vue'
import Play from '@/components/play.vue'


export default {
  name: 'HomeView',
  components: {
    Navbar,
    Whatwedo,
    ImpactStories,
    Social,
    Banner,
    Footer,
    Stories,
    Play
  
  }
  
}
</script>

<style scoped>

.landingPage{
    display: flex;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../assets/home/man.jpg);
    
    background-repeat: no-repeat;
    background-size: cover;

}

.landingDetails{
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left:  136px;
    align-self: center;
    gap: 30px;
}

.landingDetails h1{
    width: 889px;
height: 160px;

margin: 0;
    text-align: start;
    font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 72px;
line-height: 80px;
/* or 111% */

letter-spacing: -0.015em;

color: #FFFFFF;
}

.landingDetails p{
  margin: 0;
    text-align: start;
width: 841px;
height: 100px;

font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 52px;
/* or 105% */

letter-spacing: -0.015em;

color: #FFFFFF;
}
.button{
  width: 249px;
height: 64px;
}
.button a{
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 20px 70px;
gap: 10px;
font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.015em;
margin: 0;
color: #FFFFFF;
color: #FFFFFF;


text-decoration: none;
background: linear-gradient(270deg, #C4112F 0%, #F48116 100%);
border-radius: 8px;
}

@media (max-width: 1240px) {
    .landingDetails h1{
   
font-size: 60px;

}
.landingDetails{
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left:  100px;
    align-self: center;
    gap: 30px;
    padding-top: 70px;
}
  }
  @media (max-width: 1290px) {
    .landingDetails h1{
   
font-size: 50px;

}
.landingDetails{
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left:  90px;
    align-self: center;
    gap: 30px;
    padding-top: 90px;
}
  }


@media screen and (max-width: 970px) {
    .landingPage{
        background-position: 73% 100%;
    }
    .landingDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        padding-bottom: 0;
        gap: 30px;
        padding: 1rem;
    }
    .landingDetails h1 {
       width: auto;
       height: auto;
       font-size: 42px;
       line-height: 57px;
    }
    .landingDetails p {
        width: auto;
        height: auto;
        font-size: 24px;
        line-height: 34px;
     }
    }











    /*about*/
    

    .about{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .about h1{
        text-align: center;
        padding-top: 64px;
        font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 57px;
/* or 158% */

text-align: center;
letter-spacing: -0.015em;

color: #0A2540;
    }

    .about h1 span{
        color: #F48116;
    }

    .whoweare{
        display: flex;
        justify-content: center;
        gap:137px ;
        align-items: center;
        align-self: center;
        width: 80%;
        padding: 50px 0px;
    }

    .whotext{
        width: 600px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .whoImage{
       width:  534.74px;
    }
    .whoImage img{
        width:534.74px;
    }

    .whotext h1{
      font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 57px;
/* identical to box height, or 158% */
margin: 0;
text-align: left;
letter-spacing: -0.015em;

color: #0A2540;
    }
    .whotext h3{
      font-family: 'Inter Variable', sans-serif;
        font-style: normal;
        font-weight: 400;
 margin: 0;

        /* or 212% */
        margin: 0;
     
        text-align: left;
        color: #425466; 
    }
    .whotext p{
      font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
/* or 212% */
margin: 0;
letter-spacing: -0.015em;
text-align: left;
color: rgba(66, 84, 102, 0.8);
    }

    .whotext a{
      font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 29px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.015em;
margin: 0;
background: linear-gradient(90deg, #F24E1E 3.33%, #C4112F 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
    }


    @media screen and (max-width: 970px) {
        .whoweare[data-v-9ea40744] {
    
    padding: 50px 0px;
}
        .whotext {
            width: 100vw;
            display: flex;
            flex-direction: column;
            gap: 1px;
            padding: 1rem;
        }
        .whoweare {
            width: auto;
            flex-direction: column;
            margin:  1rem;
            gap: 10px;
        }
        .whoImage{
            width: auto;
        }
        .whotext h1{
          padding: 1rem;
    }
    .whoImage img{
    width: 100vw;
}
    .whotext h3{
      padding: 1rem;
    }
    .whotext p{
      padding: 1rem;
    }

    .whotext a{
        padding: 1rem;
    }
    .whotext p {
    font-size: 18px;
    line-height: 1.7;
    letter-spacing: -.015em;
}
.about h1 {
    text-align: center;
    padding-top: 36px;
   
    margin: 0;
}
    }
















    /*what we offer*/
    


    .whatweoffer{
        display: flex;
        justify-content: center;
        flex-direction: column;
   padding-top: 50px;
        width: 100vw;
        background: #FBFBFD;
        padding-bottom: 50px;
    }

    .offertext{
        width: 525px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
    
    }
    .offertext h1{
        
      font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 72px;
/* or 129% */

letter-spacing: -0.015em;
margin: 0;
color: #0A2540;
    }
    .offertext p{
      font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 167% */
padding-top: 30px;
text-align: center;
letter-spacing: -0.015em;
margin: 0;
color: #425466;
width: 911px;
    }

    .offercards{
        display: flex;
        justify-content: center;
        align-items: center;
    align-self: center;
    width: 90%;
    }

    .offercard{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* justify-content: center; */
        gap: 34px;
        text-align: center;
        align-items: center;
        align-self: center;
        margin-top: 50px;
    }

    .offercard .card{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 60px 40px;
        gap: 20px;
        
        border-radius: 8px;
       width: 100%;
        height: 500px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
    }

.offercard .card p{
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 1.7;
/* or 167% */
margin: 0;
letter-spacing: -0.015em;
text-align: left;
color: rgba(66, 84, 102, 0.8);
}

.offercard .card h3{
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
/* identical to box height, or 150% */
margin: 0;
letter-spacing: -0.015em;
text-align: left;
color: #0A2540;
}
.offercard .card:hover{
    

    background: linear-gradient(247.61deg, #F24E1E 2.36%, #F48116 91.36%);
}

.offercard .card:hover >img{
    filter: brightness(0) invert(1);
}
.offercard .card:hover >h3{
    filter: brightness(0) invert(1);
}
.offercard .card:hover >p{
    filter: brightness(0) invert(1);
}

@media screen and (max-width: 970px) {
    .offertext {
       width: auto;
        
        padding: 1rem;
    }
    .offercards {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .offercard {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* justify-content: center; */
        gap: 34px;
        
    
        width: auto;
    }
    .offercard .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 10px;
        gap: 20px;
        border-radius: 8px;
        width: auto;
        height: auto;
    }
    .offertext h1 {
    font-family: 'Inter Variable', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 72px;
    letter-spacing: -0.015em;
    margin: 0;
    color: #0A2540;
}
.offertext p {
   
    font-size: 16px;
    line-height: 1.7;
  
    margin: 0;
    color: #425466;
     width: 100vw; 
     padding: 1rem;
}
.offercards{
    
    width: 92%;
}
.whatweoffer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    width: 100vw;
    background: #FBFBFD;
    padding-bottom: 50px;
}
.offercard {
    
    margin-top: 50px;
}
.offercard .card p {
    
    text-align: center;
    color: rgba(66, 84, 102, 0.8);
}
.offercard .card h3 {
    
    text-align: center;
   
}
}
















/*where we work*/




.wherewework{
    display: flex;
    justify-content: center;
    flex-direction: column;
padding-top: 50px;
    width: 100vw;
    background: #FBFBFD;
    padding-bottom: 50px;
}
.wherewework .learnmore{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 223px;
    align-items: center;
    align-self: center;
    padding-top: 4.37rem;
    height: 72px;
}
.learnmore a{
    text-align: center;
    display: inline-flex;
padding:1.25rem 4.25rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 8px;
background: linear-gradient(90deg, #F48116 0%, #F24E1E 100%);
color: #FFFFFF;

}
.whereweworktext{
    width: 525px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;

}
.whereweworktext h1{
    
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 72px;
/* or 129% */

letter-spacing: -0.015em;
margin: 0;
color: #0A2540;
}
.whereweworktext p{
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 167% */
padding-top: 30px;
text-align: center;
letter-spacing: -0.015em;
margin: 0;
color: rgba(66, 84, 102, 0.8);
}

.whereweworkcards{
    display: flex;
    justify-content: center;
}

.whereweworkrcard{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* justify-content: center; */
    gap: 34px;
    text-align: center;
    align-items: center;
    align-self: center;
    margin-top: 50px;
}

.whereweworkrcard .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px;
    gap: 20px;
    
    border-radius: 8px;
    width: 300px;
height: 290px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
}

.whereweworkrcard .card p{

  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 167% */
margin: 0;
letter-spacing: -0.015em;
text-align: left;
color: rgba(66, 84, 102, 0.8);
}

.whereweworkrcard .card h3{
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
/* identical to box height, or 150% */
margin: 0;
letter-spacing: -0.015em;

color: #0A2540;
}

/*
.card:hover{


background: linear-gradient(247.61deg, #F24E1E 2.36%, #F48116 91.36%);
}

.card:hover >img{
filter: brightness(0) invert(1);
}
.card:hover >h3{
filter: brightness(0) invert(1);
}
.card:hover >p{
filter: brightness(0) invert(1);
}
*/

@media screen and (max-width: 970px) {
.whereweworktext {
   width: auto;
    
    padding: 1rem;
}
.whereweworkcards {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.whereweworkrcard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* justify-content: center; */
    gap: 34px;
    
    margin: 1rem;
    width: auto;
}
.whereweworkrcard .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
    gap: 20px;
    border-radius: 8px;
    width: auto;
    height: auto;
}
.wherewework{
    display: flex;
    justify-content: center;
    flex-direction: column;
padding-top: 50px;
    width: 100vw;
    background: #FBFBFD;
    padding-bottom: 50px;
}
.whereweworktext h1 {
   
    font-size: 36px;
    line-height: 72px;
    
}
.whereweworktext p {
    font-family: 'Inter Variable', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.7;
 

}
}











/*Impact in numbers*/


.Ourimpacts{
    display: flex;
    flex-direction: column;
    width: 100vw;
    
    padding-top: 50px;
    
    padding-bottom: 50px;
}
.Ourimpacts h1{
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;

/* or 129% */
padding-left: 40px;
text-align: left;
letter-spacing: -0.015em;
margin: 0;
background: linear-gradient(270deg, #F24E1E 2.45%, #F48116 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.pacts{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100vw;
    margin-top: 50px;
    height: auto;
}
.ourImpact{
    
 padding-left: 40px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* justify-content: center; */
    gap: 87px;
    text-align: center;
    align-items: self-start;
    align-self: self-start;
    
}
.map{
    width: 50%;
    
}
.map img{
    width: 100%;
    scale: 1.8;
}
.impactCard{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 10px;
gap: 10px;






border-left: 2px solid #F24E1E;
}

.impactCard h2{
  font-family: 'Inter Variable', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.7;    
    letter-spacing: -0.015em; 
    color: #425466; 
    margin: 0;
    text-align: left;
}

.impactCard p{
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 1.7;
/* identical to box height, or 188% */
text-align: left;
letter-spacing: -0.015em;
margin: 0;
color: #425466;

}


@media screen and (max-width: 970px) {
    .ourImpact {
        padding-left: 0; 
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* justify-content: center; */
        gap: 30px;
        text-align: center;
        align-items: center;
        align-self: center;
        margin-top: 90px;
    }
    .Ourimpacts{
  
    height: auto; 
    
    padding-bottom: 50px;
}
.pacts{
    flex-direction: column;
    margin-top: 20px;
}
.map{
    display: none;
}
}














</style>
