<template>
    <main class="whatwedo">
         <!-- Intro -->
      <div class="content--wrapper">
        <h2>What We Do</h2>
        <p>TECH FOR GOOD<br>
                At the heart of Kpododo Consult is a deep desire to positively impact lives. We continuously tap into the vast possibilities technology offers to improve the lives of communities and propel businesses.</p>
      </div>
    <section class="web">
  <div class="row content-story-toggle--js" style="--slideDuration: 8s;">

    <!-- Graphics area -->
    <div class="width--55">

      <div class="cs--graphics-area">

        <!-- Item 1 -->
        <div class="cs--graphics-sequence">
          <img src="../assets/home/agric.jpg" alt="">
        </div>

        <!-- Item 2 -->
        <div class="cs--graphics-sequence">
          <img src="../assets/home/edu.jpg" alt="">
        </div>
        <!-- Item 3 -->
        <div class="cs--graphics-sequence">
          <img src="../assets/home/data.jpg" alt="">
        </div>
      </div>
    </div>

    <!-- Content area -->
    <div class="width--45">

     

      <!-- Item 1 -->
      <div class="cs--content-area">
        <div class="cs--content-sequence">

          <!-- Progress indicator -->
          <div class="cs--content-progress-indicator--wrapper">
            <div class="cs--content-progress-indicator--bar"></div>
          </div>

          <!-- Title -->
          <div class="cs--content-headline">
            <h3>Communication for Social Behavior Change</h3>
          </div>

          <!-- Desc -->
          <div class="cs--content-desc">
            <p>Our social behavioral change communication campaigns utilize cutting-edge tools such as Bulk SMS, IVR, and Cinema Vans to drive positive transformations across various sectors</p> <br>
           
          </div>
        </div>
      </div>

      <!-- Item 2 -->
        <div class="cs--content-area">
            <div class="cs--content-sequence">

            <!-- Progress indicator -->
            <div class="cs--content-progress-indicator--wrapper">
                <div class="cs--content-progress-indicator--bar"></div>
            </div>

            <!-- Title -->
            <div class="cs--content-headline">
                <h3>Edutainment</h3>
            </div>

            <!-- Desc -->
            <div class="cs--content-desc">
                <p>Education + Entertainment.
Recognizing the global explosion of the internet and the enduring power of well written story lines , we incorporate educational messages into purposefully crafted entertainment media, to effect large scale social change.Through compelling narratives ,we trigger attitudinal and behavioral changes in our audiences.</p> <br>
              
            </div>
            </div>
        </div>
        <!-- Item 3 -->
        <div class="cs--content-area">
            <div class="cs--content-sequence">

                <!-- Progress indicator -->
                <div class="cs--content-progress-indicator--wrapper">
                    <div class="cs--content-progress-indicator--bar"></div>
                </div>

                <!-- Title -->
                <div class="cs--content-headline">
                    <h3>Digital Data Collection and Analysis</h3>
                </div>

                <!-- Desc -->
                <div class="cs--content-desc">
                    <p>Research plays a pivotal role in driving development interventions and influencing decision making. Eschewing traditional paper based methods,we utilize our flagship digital data collection app , Kpododo Collect, to conduct rigorous research and analysis across a wide variety of fields.</p> <br>
                   
                </div>
            </div>
         </div>
    </div>
  </div>
</section>

<section class="mobile">
  
<ol class="cards__container" title="Blog entries">
  <li class="card">
    <div class="card__thumb"><img class="animate" src="../assets/home/agric.jpg"/></div>
    <div class="card__content">
      <h3 class="card__title">Communication for Social Behavior Change</h3>
      <p class="card__text">Our social behavioral change communication campaigns utilize cutting-edge tools such as Bulk SMS, IVR, and Cinema Vans to drive positive transformations across various sectors</p>
    

    </div>
  </li>
  <li class="card">
    <div class="card__thumb"><img class="animate" src="../assets/home/edu.jpg"/></div>
    <div class="card__content">
      <h3 class="card__title">Edutainment</h3>
      <p class="card__text">Education + Entertainment.
        Recognizing the global explosion of the internet and the enduring power of well written story lines , we incorporate educational messages into purposefully crafted entertainment media, to effect large scale social change.Through compelling narratives ,we trigger attitudinal and behavioral changes in our audiences.</p>
    

    </div>
  </li>
  <li class="card">
    <div class="card__thumb"><img class="animate" src="../assets/home/data.jpg"/></div>
    <div class="card__content">
      <h3 class="card__title">Digital Data Collection and Analysis</h3>
      <p class="card__text">Research plays a pivotal role in driving development interventions and influencing decision making. Eschewing traditional paper based methods,we utilize our flagship digital data collection app , Kpododo Collect, to conduct rigorous research and analysis across a wide variety of fields.</p>
      
    </div>
  </li>

</ol>
</section>
</main>
  </template>
  

  <script>
 import { gsap } from 'gsap';

export default{
    mounted(){
        
let contentStoryToggleElement = document.querySelectorAll(
  ".content-story-toggle--js"
);


contentStoryToggleElement.forEach((wrapper) => {
 
  let imgSequences = wrapper.querySelectorAll(".cs--graphics-sequence");
  let contentProgressBar = wrapper.querySelectorAll(
    ".cs--content-progress-indicator--bar"
  );
  let contentDesc = wrapper.querySelectorAll(".cs--content-desc");
  let contentArea = wrapper.querySelectorAll(".cs--content-area");
  let contentArray = Array.from(contentArea);
  var intervalId;

  contentArray[0].classList.add("is--active");
  toggleSequences();

  autoplaySequence();

  
  contentArea.forEach((item) => {
    item.addEventListener("click", (e) => {
     
      stopInterval();
     
      if (!e.currentTarget.classList.contains("is--active")) {
        gsap.to(contentDesc, { height: "0", duration: 0.25 });
        headerClicked(e);
      }
    });
  });

  
  function autoplaySequence() {
    let slide = 1;

  
    let duration = wrapper.style.getPropertyValue("--slideDuration");

    const timeout = duration.replace("s", "000");

    intervalId = setInterval(() => {
      activeSlide(slide);
    }, timeout);

    
    function activeSlide(i) {
      let activeWrapper = contentArray[i];
      let expandableItem = activeWrapper.querySelector(".cs--content-desc");

   
      gsap.to(contentDesc, { height: "0", duration: 0.25 });
     
      gsap.to(expandableItem, { height: "auto", duration: 0.25 });
     
      activeWrapper.classList.add("is--active");
      
      contentArea.forEach((header) => {
        if (header !== activeWrapper) {
          header.classList.remove("is--active");
        }
      });
     
      toggleSequences();

 
      if (slide >= contentArray.length - 1) {
        slide = 0;
      } else {
        slide++;
      }
    }
  }

  function stopInterval() {

    clearInterval(intervalId);

    contentProgressBar.forEach((p) => {
      if (!p.classList.contains("paused")) {
        p.classList.add("paused");
      }
    });
  }


  function headerClicked(element) {
    let expandableItem = element.currentTarget.querySelector(
      ".cs--content-desc"
    );

  
    gsap.to(expandableItem, { height: "auto", duration: 0.25 });
 
    element.currentTarget.classList.add("is--active");
  
    contentArea.forEach((header) => {
      if (header !== element.currentTarget) {
        header.classList.remove("is--active");
      }
    });
   
    toggleSequences();
  }


  function toggleSequences() {
    let imgArray = Array.from(imgSequences);
    let active = 0;

    for (let i = 0; i < contentArray.length; i++) {
      imgArray[i].classList.remove("is--active");
      if (contentArray[i].classList.contains("is--active")) {
        active = i;
      }
    }
    imgArray[active].classList.add("is--active");
  }
});

    }
}



  </script>
  

<style scoped>
.whatwedo{
  padding-bottom: 50px;
}
	.content-story-toggle--js .cs--graphics-area {
  display: grid;
}
.content-story-toggle--js .cs--graphics-area .cs--graphics-sequence {
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}
.content-story-toggle--js .cs--graphics-area .cs--graphics-sequence.is--active {
  opacity: 1;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence {
  position: relative;
  min-height: 28px;
  padding: 0 0 0 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence .cs--content-progress-indicator--wrapper {
  position: absolute;
  display: inline-block;
  width: 3px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #dcdcdc;
  border-radius: 3px;
  will-change: max-height;
  overflow: hidden;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence .cs--content-progress-indicator--wrapper .cs--content-progress-indicator--bar {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #3c4043;
  scale: 1 0;
  transform-origin: top;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence .cs--content-headline {
  cursor: pointer;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence .cs--content-headline h3 {
  opacity: 0.6;
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence .cs--content-headline h3:hover {
  opacity: 1;
}
.content-story-toggle--js .cs--content-area .cs--content-sequence .cs--content-desc {
  height: 0px;
  overflow: hidden;
}
.content-story-toggle--js .cs--content-area.is--active .cs--content-sequence .cs--content-progress-indicator--wrapper .cs--content-progress-indicator--bar {
  animation: fillProgress linear;
  animation-duration: var(--slideDuration, 2s);
}
.content-story-toggle--js .cs--content-area.is--active .cs--content-sequence .cs--content-progress-indicator--wrapper .cs--content-progress-indicator--bar.paused {
  animation: none;
}
.content-story-toggle--js .cs--content-area.is--active .cs--content-sequence .cs--content-headline h3 {
  color: #3c4043;
  opacity: 1;
}
.content-story-toggle--js .cs--content-area.is--active .cs--content-sequence .cs--content-desc {
  height: auto;
}
@keyframes fillProgress {
  0% {
    scale: 1 0;
  }
  100% {
    scale: 1 1;
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.content--wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}
.content--wrapper p{
    width: 911px;
height: 120px;


font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 167% */

text-align: center;
letter-spacing: -0.015em;

color: rgba(66, 84, 102, 0.8);
}
h2 {
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 72px;
/* identical to box height, or 129% */
margin: 0;
text-align: center;
letter-spacing: -0.015em;

color: #0A2540;

}
h3 {
  margin: 0;
  text-align: left;
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 68px;
/* or 142% */

letter-spacing: -0.015em;

color: #0A2540;
}
p {
  margin: 0;
  text-align: left;
  font-family: 'Inter Variable', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 30px;
/* or 167% */

letter-spacing: -0.015em;

color: rgba(66, 84, 102, 0.8);

}
img {
  display: block;
  width: 100%;
}
section {
  position: relative;
  display: flex;
  width: 80%;
  height: 100%;
 margin-top: 50px !important;

  margin: auto;

}
section .row {
  display: flex;
 gap: 50px;
  justify-content: center;
  align-items: center;
  align-self: center;
}
section .row .width--55 {
  width: 50%;
 
}
section .row .width--45 {
  width: 50%;
  
}
section .row .width--45 .content--wrapper {
  padding-bottom: 12px;
}
section .row .width--45 .content--wrapper p {
  margin-top: 16px;
}

a{
  text-decoration: none;
    background: none;
    color: #540083;
    border-radius: 8px!important;
    border: none;
    margin-top: 1rem;
    cursor: pointer;
    font-weight: 400;
    font-size: 19px;
    text-decoration: none;
  
}

.cs--graphics-sequence img{

  object-fit: cover;
}


.mobile{
  display: none;
}


@media screen and (max-width: 970px) {
   
 .web{
  display: none;
 }
 .mobile{
  display: block;
 }
 h3 {
    text-align: left;
    font-weight: 500;
    font-size: 48px;
    line-height: 1.5;
}
.content--wrapper p {
     width: auto; 
    height: auto;
    font-family: Inter Variable,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.7;
    text-align: center;
    letter-spacing: -.015em;
    color: rgba(66,84,102,.8);
    padding: 1rem;
}
.content--wrapper h2{
    font-family: 'Inter Variable', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 72px;
    margin: 0;
    text-align: center;
    letter-spacing: -0.015em;
    color: #0A2540;
}
.content--wrapper {
  
    padding-top: 0px;
}
}












ol {
  list-style-type: none;
}
ol li {
  display: inline-block;
}

img,
svg {
  width: 100%;
  vertical-align: top;
}

/* :: card :: */
.cards__container {
  display: grid;
  grid-template-columns: 1fr;
  margin: 1.875rem auto;
  max-width: 53.125rem;
  width: 95%;
  gap: 3.75rem;
}

.card {
  display: grid;
  grid-template-columns: 1fr;
}
.card__thumb {
  overflow: hidden;
  background-color: var(--grey);
}
.card__thumb img {
  transition: all 0.3s ease;
}
.card__thumb:hover img {
  transform: scale(1.1);
}
.card__content {
  background-color: white;
  padding: 2.1875rem 0.9375rem 3.4375rem;
  position: relative;
  text-align: center;
}
.card__title {
  color: var(--dark);
  font-family: 'Inter Variable', sans-serif;
  font-size: 2.1875rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
}
.card__text {
  line-height: 1.7;
}
.card__btn {
  background: var(--dark-grey);
  border: none;
  bottom: -1.25rem;
  left: 50%;
  padding: 0.625rem;
  position: absolute;
  transform: translateX(-50%);
  transition: all 0.3s ease;
}
.card__btn:hover, .card__btn:focus {
  background-color: var(--grey);
}
.card__btn img {
  width: 1.25rem;
}

@media (min-width: 37.5rem) {
  .card {
    grid-template-columns: auto minmax(6.25rem, 25rem) auto;
    grid-template-rows: repeat(4, auto);
  }
  .card__thumb {
    width: 100%;
    max-width: 34.375rem;
    justify-self: center;
    grid-area: 1/1/-2/-1;
  }
  .card__content {
    grid-area: 3/2/-1/-2;
    padding: 2.8125rem 2.8125rem 3.4375rem;
  }
}
@media (min-width: 56.25rem) {
  .card {
    grid-template-columns: auto 4.375rem minmax(6.25rem, 25rem);
    grid-template-rows: repeat(3, auto);
  }
  .card:nth-child(even) {
    grid-template-columns: minmax(6.25rem, 25rem) 4.375rem auto;
  }
  .card__thumb {
    grid-area: 1/1/-1/3;
  }
  .card:nth-child(even) .card__thumb {
    grid-area: 1/2/-1/-1;
  }
  .card__content {
    grid-area: 2/2/-2/-1;
    text-align: left;
  }
  .card:nth-child(even) .card__content {
    text-align: left;
    grid-area: 2/1/-2/3;
  }
}

</style>